import { AuthenticatedGuard } from './core/guards/authenticated.guard';
import { AuthenticationGuard } from './core/guards/authentication.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: 'app',
    loadChildren: () => import('./application/app.module').then(d => d.AppModule),
    // canActivate: [AuthenticatedGuard],
    // canLoad: [AuthenticatedGuard]
  },
  // {
  //   path: 'authentication',
  //   loadChildren: () => import('./authentication/authentication.module').then(d => d.AuthenticationModule),
  //   // canActivate: [AuthenticationGuard],
  //   // canLoad: [AuthenticationGuard]
  // },
  {
    path: 'authentication',
    loadChildren: () =>
      import('./modules/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: '**',
    redirectTo: 'app',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
