import { NgModule } from "@angular/core";
import { ApolloModule, APOLLO_OPTIONS,Apollo  } from "apollo-angular";
import { HttpLinkModule, HttpLink } from "apollo-angular-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpHeaders } from "@angular/common/http";
import { ApolloLink, concat, Observable } from "apollo-link";
import { setContext } from "apollo-link-context";
import { AngularFireAuth } from '@angular/fire/auth';
import gql from "graphql-tag";
import { onError } from "apollo-link-error";
import { AuthService } from "./core/services/auth.service";
import { RcgUser } from './core/models/rcg-user';

//secret:secre!1234!!
const uri = "https://qx-gql.assist.si/v1/graphql" 
//"https://scheduler-gql.assist.si/v1/graphql"; // <-- add the URL of the GraphQL server here

// const errorlink = onError(({ graphQLErrors, networkError, operation, forward }) => {
//   if (graphQLErrors) {
//     for (let err of graphQLErrors) {
//       console.log("graphqltokenerror", err)
//       switch (err.extensions.code) {
//         case 'invalid-jwt':
//           // error code is set to UNAUTHENTICATED
//           // when AuthenticationError thrown in resolver

//           // modify the operation context with a new token
//           const oldHeaders = operation.getContext().headers;
//           //localStorage.setItem("user",null);
//           const token = JSON.parse(localStorage.getItem("user"))?.stsTokenManager?.accessToken;
//           console.log("expired_token",token)
//           operation.setContext({
//             headers: {
//               // ...oldHeaders,
//               authorization:  `Bearer ${token}`,
//               Accept: "charset=utf-8",
//             },
//           });
//           // retry the request, returning the new observable
//           return forward(operation);
//       }
//     }
//   }
//   if (networkError) {
//     console.log(`[Network error]: ${networkError}`);
//     // if you would also like to retry automatically on
//     // network errors, we recommend that you use
//     // apollo-link-retry
//   }
// }
// );



// const errorlink = onError(({ graphQLErrors, networkError, operation, forward }) => {
//   if (graphQLErrors) {
//     for (let err of graphQLErrors) {
//       console.log("graphqltokenerror", err)
//       switch (err.extensions.code) {
//         case 'invalid-jwt':

//           return new Observable(observer => {
//             const token = JSON.parse(localStorage.getItem("user"))?.stsTokenManager?.accessToken;
//             console.log("expired_mytoken",token)
//             operation.setContext(({ headers = {} }) => ({
//               headers: {
//                 // Re-add old headers
//                 ...headers,
//                 // Switch out old access token for new one
//                 authorization:  `Bearer ${token}`,
//               }
//             }));
//             const subscriber = {
//               next: observer.next.bind(observer),
//               error: observer.error.bind(observer),
//               complete: observer.complete.bind(observer)
//             };

//             // Retry last failed request
//             forward(operation).subscribe(subscriber);
//           })
//           }
//       }
//     }
//   if (networkError) {
//     console.log(`[Network error]: ${networkError}`);
//     // if you would also like to retry automatically on
//     // network errors, we recommend that you use
//     // apollo-link-retry
//   }
// }
// );

// export function createApollo(httpLink: HttpLink) {
//   const basic = setContext((operation, context) => ({
//     headers: {
//       Accept: "charset=utf-8",
//     },
//   }));
//   console.log("createApollo")
//   // Get the authentication token from local storage if it exists
//   const token = JSON.parse(localStorage.getItem("user"))?.stsTokenManager?.accessToken;
//   console.log("token",token)

//   // const authLink = new ApolloLink((operation, forward) => {
//   //   const accessToken = JSON.parse(localStorage.getItem("user"))?.stsTokenManager?.accessToken;
  
//   //   operation.setContext(({ headers }: { headers: Headers }) => ({
//   //     headers: {
//   //       Authorization: `Bearer ${accessToken}`,
//   //     },
//   //   }))
//   //   return forward(operation)
//   // })

//     const authLink = new ApolloLink((operation, forward) => {
//     // const accessToken = JSON.parse(localStorage.getItem("user"))?.stsTokenManager?.accessToken;
  
//     // operation.setContext(({ headers }: { headers: Headers }) => ({
//     //   headers: {
//     //     Authorization: `Bearer ${accessToken}`,
//     //   },
//     // }))
//     // return forward(operation)
//     return new Observable(observer => {
//       const token = JSON.parse(localStorage.getItem("user"))?.stsTokenManager?.accessToken;
//       console.log("set_mytoken",token)
//       operation.setContext(({ headers = {} }) => ({
//         headers: {
//           // Re-add old headers
//           ...headers,
//           // Switch out old access token for new one
//           authorization:  `Bearer ${token}`,
//         }
//       }));
//       const subscriber = {
//         next: observer.next.bind(observer),
//         error: observer.error.bind(observer),
//         complete: observer.complete.bind(observer)
//       };

//       // Retry last failed request
//       forward(operation).subscribe(subscriber);
//     })
//   })

//   let link;
//   const cache = new InMemoryCache();

//   if (token) {

//     link = ApolloLink.from([errorlink,basic, authLink, httpLink.create({ uri })]);
//   } else {
//     link = ApolloLink.from([basic, httpLink.create({ uri })]);
//   }

//   return {
//     link,
//     cache,
//   };
//   // return {
//   //   link: httpLink.create({
//   //     uri: uri,
//   //       headers: new HttpHeaders({
//   //         "x-hasura-admin-secret": "secre!1234!!"})
//   //   }),
//   //   cache: new InMemoryCache(),
//   // };
// }

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [Apollo, HttpLink],
  // providers: [
  //   {
  //     provide: APOLLO_OPTIONS,
  //     useFactory: createApollo,
  //     deps: [HttpLink,AngularFireAuth],
  //   },
  // ],
})
export class GraphQLModule {

  user$: Observable<RcgUser>;
  user: RcgUser;
  
  constructor(apollo: Apollo, httpLink: HttpLink, authService: AuthService){
    const auth = setContext(async (_, { headers }) => {
      // let token = authService.token;
      // authService.user$.toPromise().then(x=>{token=x.token})
      //let token =JSON.parse(localStorage.getItem("user"))?.stsTokenManager?.accessToken
      let token =await authService.tokenId();
     if(token){
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
    }
    );
  
    apollo.createDefault({
      link: ApolloLink.from([
        auth,
        httpLink.create({
          uri: uri,
        }),
      ]),
      cache: new InMemoryCache(),
    });


  }
}
