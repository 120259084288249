import { catchError } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { IError } from 'src/app/core/interfaces/error';
import { ErrorType } from 'src/app/core/enums/error-type.enum';
import { errorsGlobal } from 'src/app/core/services/application.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent implements OnInit {
  errors: Observable<Array<IError>>;
  constructor() { }

  ngOnInit(): void {
    this.errors = errorsGlobal.asObservable();

  }

  removeError(e: IError) {
    const errors = errorsGlobal.getValue();
    const errorId = errors.findIndex(x => x.id === e.id);
    if (errorId > -1) {
      errors.splice(errorId, 1);
      errorsGlobal.next([...errors]);
    }
  }

}
