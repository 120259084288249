import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ApplicationService, errorsGlobal } from './core/services/application.service';
import { IServerData } from './core/interfaces/server-data';
import { Observable } from 'rxjs';
import { IError } from './core/interfaces/error';
 import { SwUpdate } from '@angular/service-worker';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  title = 'contactCenter';
  serverData: Observable<IServerData>;
  errors: Observable<Array<IError>>;

  constructor(
    private applicationService: ApplicationService,
    public updates:SwUpdate
  ) {
    updates.available.subscribe(event => {
      console.log('current version is', event.current);
      console.log('available version is', event.available);
    });
    updates.activated.subscribe(event => {
      console.log('old version was', event.previous);
      console.log('new version is', event.current);
    });

    updates.available.subscribe(event => {

        updates.activateUpdate().then(() => this.updateApp());
    });
    // this.serverData = this.applicationService.getServerData();
    // this.errors = errorsGlobal.asObservable();
  }

  updateApp(){
    document.location.reload();
    console.log("The app is updating right now");

   }
}
