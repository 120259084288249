import { ElementRef, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { auth } from "firebase/app";
import { AngularFireAuth } from "@angular/fire/auth";
import { User } from "firebase";
import { RcgUser } from "../models/rcg-user";
import { BehaviorSubject, fromEvent, Observable, Subscription } from "rxjs";
import { Disposable } from "./disposable";
import { Apollo } from "apollo-angular";
import { debounceTime, distinctUntilChanged, filter, map, tap } from "rxjs/operators";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class AuthService implements Disposable {
  public userSubject: BehaviorSubject<RcgUser>;
  private authStateSubs: Subscription;

  user$: Observable<RcgUser>;
  beforeLogoutRoute = "";

  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
    private apollo: Apollo
  ) {
    this.userSubject = new BehaviorSubject<RcgUser>(
      JSON.parse(localStorage.getItem("user1") ?? null)
    );

    this.user$ = this.userSubject.asObservable();

    this.authStateSubs = this.afAuth.authState.subscribe(
      async (fbUser: User) => {
        try {
          if (fbUser) {
            localStorage.setItem('user', JSON.stringify(fbUser));
            const rcgUser = await this.fbUsertoRcgUser(fbUser);
            const myUser:RcgUser=rcgUser;
            if (rcgUser != null) {
               await this.getUserRights(
                JSON.parse(atob(rcgUser.token.split(".")[1]))[
                  "https://hasura.io/jwt/claims"
                ]["x-hasura-user-id"]
              ).then((y) => {
                myUser.roleId=y.role_id;
                myUser.uporabnikId=y.uporabnik_id;
                myUser.user_id=y.user_id;
              }); //.toPromise().then(r=>{console.log("then",r)})
              this.saveUser(myUser);
            } else {
              this.removeUser();
            }
          } else {
            this.removeUser();
          }
        } catch (error) {
          this.removeUser();
        }
      }
    );
  }

  // async getUserData(): Promise<any[]> {
  //   try {
  //     let response = await this.http
  //       .get(this.heroesUrl)
  //       .toPromise();
  //     return response.json().data as Hero[];
  //   } catch (error) {
  //     await this.handleError(error);
  //   }
  // }

  get token(): string {
    const user = this.userSubject.value;
    return user?.token;
  }

  async tokenId(): Promise <string> {
    const user= await this.afAuth.currentUser;
    const token=await user?.getIdToken();
    return token;
  }

  get isLoggedIn(): boolean {
    return this.userSubject.value !== null;
  }

  async login(email: string, password: string) {
    const result = await this.afAuth.signInWithEmailAndPassword(
      email,
      password
    );
    const rcgUser = await this.fbUsertoRcgUser(result?.user);
    if (rcgUser) {
      //this.saveUser(rcgUser);
      this.router.navigate(["/home"]);
    } else {
      throw new Error("No user from firebase");
    }
  }

  async logout() {
    await this.afAuth.signOut();
    this.removeUser();
    this.setLastRouteBeforeLogOut();
    this.router.navigate(["/authentication/login"]);
  }

  setLastRouteBeforeLogOut() {
    this.beforeLogoutRoute = this.router.url;
    console.log("last route", this.beforeLogoutRoute);
  }

  async loginWithGoogle() {
    await this.afAuth.signInWithPopup(new auth.GoogleAuthProvider());
    this.router.navigate(["home"]);
  }

  private async fbUsertoRcgUser(fbUser: User) {
    if (fbUser) {
      const token = await fbUser.getIdToken();
      if (token) {
        //console.log(fbUser);
        return {
          email: fbUser.email,
          uid: fbUser.uid,
          token: token,
        };
      }
      return null;
    }
    return null;
  }

  private saveUser(user: RcgUser) {
    localStorage.setItem("user1", JSON.stringify(user));
    this.userSubject.next(user);
  }

  private removeUser() {
    localStorage.removeItem("user1");
    this.userSubject.next(null);
  }

  async getUserRights(userid: number) {
    return this.apollo
      .query({
        query: gql`
          query MyQuery($userId: Int) {
            zavod_users(where: { user_id: { _eq: $userId } }) {
              role_id
              uporabnik_id
              user_id
            }
          }
        `,
        variables: {
          userId: userid,
        },
      })
      .toPromise()
      .then((x) => {
        return x.data["zavod_users"][0];
      });
  }

  email: string;


  updateEmailFromRef(elementRef: ElementRef) {
    return fromEvent(elementRef.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(250),
        distinctUntilChanged(),
        tap((_) => {
          this.email = elementRef.nativeElement.value;
        })
      ).subscribe();
  }
  
  async forgotPassword() {
    try {
      await this.afAuth.sendPasswordResetEmail(this.email);
      this.router.navigate(['authentication', 'login']);
    } catch (err) {
      this.errorDialog(err);
    }
  }

  errorDialog(message: string): void {
    console.log(message)
  }

  // dispose resources
  dispose() {
    this.authStateSubs?.unsubscribe();
  }
}
