// import { HttpService } from './../services/http.service';
import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate, CanLoad {
  constructor(
    // private httpService: HttpService,
    private router: Router
  ) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // const auth = this.httpService.isAuthenticated();
    // if (auth) {
    //   this.router.navigate(['/app']);
    // }
    // return !auth;
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    // const auth = this.httpService.isAuthenticated();
    // if (auth) {
    //   this.router.navigate(['/app']);
    // }
    // return !auth;
    return true;
  }
}
