import { ApplicationService } from './services/application.service';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { AuthenticationGuard } from './guards/authentication.guard';
// import { AuthenticationService } from './services/authentication.service';
import { NgModule, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { HttpService } from './services/http.service';
// import { SignalrService } from './services/signalr.service';
// import { DataService } from './services/data.service';
// import { HTTP_INTERCEPTORS } from '@angular/common/http';
// import { HttpClientInterceptor } from './interceptors/http.interceptor';
import { UserDataGuard } from './guards/user-data.guard';


export const CoreServices = [
  // HttpService,
  // AuthenticationService,
  ApplicationService,
  // SignalrService,
  // DataService
];

export const CoreGuards = [
  AuthenticationGuard,
  AuthenticatedGuard,
  UserDataGuard
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: HttpClientInterceptor, multi: true }
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [...CoreServices, ...CoreGuards]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
