import { IError } from 'src/app/core/interfaces/error';
import { Observable, BehaviorSubject } from 'rxjs';
import { IServerData } from './../interfaces/server-data';
// import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { IModule } from '../interfaces/module';
import { map, catchError } from 'rxjs/operators';
import { IUserData } from '../interfaces/user-data';

export let errorsGlobal = new BehaviorSubject<Array<IError>>([]);

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(
    // private httpService: HttpService
  ) { }

  // getUserData(): Observable<boolean> {
  //   return this.httpService.get<IUserData>('user/data', true).pipe(
  //     map(x => {
  //       this.httpService.setUserData(x);
  //       return true;
  //     }
  //   ));
  // }

  // getModules(): Observable<Array<IModule>> {
  //   return this.httpService.get<Array<IModule>>('user/modules', true);
  // }

  // getServerData(): Observable<IServerData> {
  //   return this.httpService.get<IServerData>('public/serverData', false).pipe(map(x => this.httpService.setServerData(x)));
  // }
}
